import { createTheme } from '@mui/material/styles'
import { colors } from '@mui/material'
import variable from './scss/variables.scss'
import { theme } from './theme'

export const themeMui = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: variable.xlTextSize,
      fontWeight: variable.medium,
    },
    h2: {
      fontSize: variable.lgTextSize,
      fontWeight: variable.bold,
    },
    h3: {
      fontSize: variable.mdTextSize,
      fontWeight: variable.bold,
    },
    h4: {
      fontSize: variable.smTextSize,
      fontWeight: variable.bold,
    },
    h5: {
      fontSize: variable.xsTextSize,
      fontWeight: variable.bold,
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: colors.red[500],
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: 'white',
          },
          minHeight: '48px',
          '& .MuiInputBase-input': {
            height: '48px',
            boxSizing: 'border-box',
          },
          '& .Mui-disabled': {
            backgroundColor: '#eeeeee',
            color: '#949494',
            borderRadius: '4px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          fontWeight: 500,
          fontSize: '16px',
          textTransform: 'none',
          lineHeight: '24px',
          '&.MuiButton-contained.Mui-disabled': {
            background: theme.colors.primary500,
            color: '#fff',
            opacity: 0.7,
          },
          '&.MuiButton-outlined': {
            border: `2px solid ${theme.colors.primary500}`,
            color: theme.colors.primary500,
            '&.MuiButton-outlined.Mui-disabled': {
              opacity: 0.7,
              color: theme.colors.primary500,
            },
          },
          '&.MuiButton-contained': {
            backgroundColor: theme.colors.primary500,
            color: '#fff',
            '&:hover': {
              backgroundColor: theme.colors.primary500,
            },
          },
        },
        sizeMedium: {
          height: '48px',
        },
        sizeSmall: {
          fontWeight: 700,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            backgroundColor: 'white', // Aplica fundo branco no ícone SVG do checkbox
            borderRadius: '4px', // Para manter consistência de estilo
          },
          '& .MuiCheckbox-indeterminate': {
            '& .MuiSvgIcon-root': {
              backgroundColor: 'white', // Fundo branco no ícone indeterminado também
            },
          },
          '&.Mui-disabled': {
            '& .MuiSvgIcon-root': {
              '& path:first-of-type': {
                color: '#949494',
                fill: '#949494',
              },
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        indicator: {
          backgroundColor: theme.colors.primary700,
          top: 0,
          height: '2px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          '&.Mui-selected': {
            color: theme.colors.primary700,
            backgroundColor: '#ffffff',
            height: '44px',
          },
          '&:not(.Mui-selected)': {
            borderBottom: 'none',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          maxHeight: '48px',
          backgroundColor: 'white',
          '&.Mui-disabled': {
            backgroundColor: '#eeeeee', // Aplica fundo cinza ao select desabilitado
            color: '#949494',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#eeeeee', // Aplica fundo cinza ao input desabilitado
            color: '#949494',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#eeeeee',
            color: '#949494',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0,
          '& .MuiInputBase-root': {
            minHeight: '48px',
            backgroundColor: 'white',
          },
          '& .Mui-disabled': {
            backgroundColor: '#eeeeee',
          },
          '& .MuiInputBase-input': {
            height: '48px',
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            padding: 0
          }
        },
      },
    },
  },
})
