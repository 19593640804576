import React, { CSSProperties } from 'react'
import './preloader.scss'

interface Props {
  isShow?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
  className?: string
  style?: CSSProperties
}

// TODO: add default styles display: 'block', margin: '0 auto' and remove isShow property
export const Preloader = ({ size = 'md', className, style }: Props) => <svg
  className={ `preloader preloader__${ size } ${ className }` }
  fill='currentColor'
  data-testid='preloader'
  xmlns='http://www.w3.org/2000/svg'
  x='0px'
  y='0px'
  viewBox='0 0 34 32'
  style={ style }
>
  <path
    className='t-c-primary500'
    d='M1,22h10v-3h0c0-2.7-2.2-5-5-5c-2.7,0-5,2.2-5,5h0V22L1,22z'
  />
  <path
    className='t-c-primary500'
    d='M12,16.1h10v-3h0c0-2.7-2.2-5-5-5c-2.7,0-5,2.2-5,5h0V16.1L12,16.1z'
  />
  <path
    className='t-c-primary500'
    d='M23,9h10V6h0c0-2.7-2.2-5-5-5c-2.7,0-5,2.2-5,5h0V9L23,9z'
  />
  <path
    className='t-c-success'
    d='M11,23H1v3h0c0,2.7,2.2,5,5,5c2.7,0,5-2.2,5-5h0V23L11,23z'
  />
  <path
    className='t-c-success'
    d='M22,17.1H12V26h0c0,2.7,2.2,5,5,5c2.7,0,5-2.2,5-5h0V17.1L22,17.1z'
  />
  <path
    className='t-c-success'
    d='M33,10H23v16h0c0,2.7,2.2,5,5,5c2.7,0,5-2.2,5-5h0V10L33,10z'
  />
</svg>
