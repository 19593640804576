import { request } from './axios'
import { Distributor } from '../component/common'
import type { TUploaded, THistoryData } from '../type'
import { AxiosResponse } from 'axios'
import { createDownloadLink } from '../utility/download-link'

export const Upload = {

  create: async (ce_340b_id: string, file_type: string, distributor: string, dea_id: string, files: File[], updateProgress: any): Promise<AxiosResponse<TUploaded>> => {
    let url = `/aux/upload/?ce_340b_id=${ ce_340b_id }&file_type=${ file_type }`
    if (file_type === Distributor.INVOICES) {
      url += `&dea_id=${ dea_id }&distributor=${ distributor }`
    }

    const formData = new FormData()
    files.forEach((file, i) => formData.append(`file${i}`, file))

    return request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url,
      method: 'post',
      data: formData,
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          updateProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        }
      }
    })
  },

  template: async (file_type: string): Promise<void> => {
    const { data } = await request({
      responseType: 'arraybuffer',
      url: `/aux/download/template/?file_type=${ file_type }`,
      method: 'post'
    })
    createDownloadLink(data, `aux_template_${ file_type }.xlsx`)
  },

  get: async (page_number = 1): Promise<THistoryData> => {
    const { data: { history, max_page_size, page_no, total_count, total_pages } } = await request({
      url: `/aux/history/?page_number=${ page_number }`
    })

    return { history, max_page_size, page_no, total_count, total_pages }
  },

  excel: async (): Promise<void> => {
    const { data } = await request({
      responseType: 'arraybuffer',
      url: '/aux/history/export_to_excel/',
      method: 'post'
    })
    createDownloadLink(data, 'aux_uploads_history.xlsx')
  }
}
