import React, { createRef, useCallback, useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Stack, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { JsonEditor as Editor } from 'jsoneditor-react'
import ace from 'brace'
import 'brace/mode/json'
import 'brace/ext/language_tools'
import 'jsoneditor-react/es/editor.min.css'
import './JSONViewer.scss'

interface IProps {
  isShow: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  title: string;
  json: object;
  handleJSON: (json: object) => void;
  handleSave: () => void;
  handleClose: () => void;
}

export const JSONViewer = ({ isShow, isLoading, isDisabled, title, json = {}, handleJSON, handleSave, handleClose }: IProps) => {
  const [isError, setIsError] = useState(false)
  const viewerRef = createRef<Editor>()

  const expandAll = useCallback(() => {
    if (viewerRef.current && viewerRef.current.expandAll) {
      viewerRef.current.expandAll()
    }
  }, [viewerRef])

  useEffect(() => {
    if (isShow && !isLoading) {
      setTimeout(() => {
        expandAll()
      }, 0)
    }
  }, [isShow, isLoading, expandAll])

  useEffect(() => {
    if (isDisabled) {
      expandAll()
    }
  }, [expandAll, isDisabled])

  const handleInput = (json: object) => {
    handleJSON(json)
  }

  const handleValidation = (errors: object[]) => {
    setIsError(errors.length > 0)
  }

  return (
    <Dialog
      open={isShow}
      onClose={handleClose}
      fullWidth
      maxWidth='md'
      disableEscapeKeyDown
      aria-labelledby='dialog-title'
      data-test='json-viewer-dialog'
    >
      <DialogTitle
        id='dialog-title'
        data-test='dialog-title'
        sx={{ m: 0, p: 2 }}
      >
        {title}
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <CircularProgress
            className='d-b m-l-auto m-r-auto'
            data-test='preloader'
          />
        ) : (
          <Editor
            ref={viewerRef}
            value={json}
            ace={ace}
            mode={isDisabled ? 'view' : 'code'}
            enableSort={false}
            enableTransform={false}
            onValidationError={(errors: object[]) => handleValidation(errors)}
            onChange={(json: object) => handleInput(json)}
            data-test='json-editor'
          />
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          spacing={2}
          direction='row'
          justifyContent={'space-between'}
          width={'100%'}
          paddingX={2}
          paddingY={1}
        >
          <Button
            onClick={handleClose}
            data-test='cancel-button'
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          {!isDisabled && (
            <Button
              variant='contained'
              disabled={isError}
              onClick={handleSave}
              data-test='save-button'
            >
              Save
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
