export const TEXT = {
  menu: {
    home: 'Home',
    upload: 'Upload',
    data_input: 'Data Input',
    category_classification: 'Category Classification',
    fee_calculation: 'Fee Calculation',
    field_configuration: 'Field Configuration',
    fee_management: 'Fee Management',
    support: 'Support',
    admin: 'Admin',
    synchronization: 'Synchronization',
    user_roles_management: 'User Roles Management',
    aux_rules_management: 'AUX Rules Management',
    ad_management: 'AD Management',
    users: 'Users',
    covered_entities: 'Covered Entities',
    memberships: 'Memberships',
    pharmasee: 'Pharmasee'
  },
  screen: {
    home: {
      title: 'Home'
    },
    upload: {
      title: 'Upload'
    },
    data_input: {
      title: 'Data Input'
    },
    field_configuration: {
      title: 'Field Configuration'
    },
    fee_management: {
      title: 'Fee Management'
    },
    support: {
      title: 'Support'
    },
    synchronization: {
      title: 'Synchronization'
    },
    user_roles_management: {
      title: 'User Roles Management'
    },
    aux_rules_management: {
      title: 'AUX Rules Management'
    },
    pharmasee: {
      title: 'Pharmasee'
    },
    users: {
      title: 'Manage Users'
    },
    category_classification: {
      title: 'Category Classification'
    },
    covered_entities: {
      title: 'Manage Covered Entities'
    },
    memberships: {
      title: 'Manage Memberships'
    }
  }
}
