import { request } from './axios'
import type { TGroup, TGroups } from '../type'

export const Group = {

  get: async (resources_group = ''): Promise<TGroups> => {
    const { data: { resource_map, upload_date } } = await request({
      url: `/auth/resources/?resources_group=${ resources_group }`
    })

    return {
      resource_map,
      upload_date
    }
  },

  update: async (data: TGroups): Promise<void> => {
    await request({
      url: '/auth/resources/',
      method: 'post',
      data
    })
  },

  edit: async (group: string, data: TGroup): Promise<void> => {
    await request({
      url: `/auth/resources/?resources_group=${ group }`,
      method: 'put',
      data
    })
  },

  add: async (data: TGroup): Promise<void> => {
    await request({
      url: '/auth/resources/group/',
      method: 'post',
      data
    })
  },

  delete: async (resources_group = ''): Promise<void> => {
    await request({
      url: `/auth/resources/?resources_group=${ resources_group }`,
      method: 'delete'
    })
  }
}
