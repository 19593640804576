import { request } from './axios'
import { Import, Export } from '../type/admin'

export const Synchronization = {

  export: async (): Promise<Export> => {
    const { data } = await request({
      url: '/dev/datasync/export/',
      method: 'post'
    })

    return data
  },

  import: async (): Promise<Import> => {
    const { data } = await request({
      url: '/dev/datasync/import/',
      method: 'post'
    })

    return data
  }
}
