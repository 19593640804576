import React, { createContext } from 'react'
import { permissions } from '../store/reducer/app'
import { useAppSelector } from '../store/hooks'
import { PermissionAccess, PermissionKey } from './'

interface PermissionProviderProps {
  children: React.ReactNode
  permission: PermissionKey
  className?: string
}

const PermissionContext = createContext<{ permission: PermissionKey | undefined }>({ permission: undefined })

export const PermissionProvider: React.FC<PermissionProviderProps> = ({ permission, children, className = '' }: PermissionProviderProps) => {
  const currentPermissions = useAppSelector(permissions)
  const isSuperPermission = currentPermissions['*']
  const currentPermission = currentPermissions[permission]
  const isDisabled = currentPermission === PermissionAccess.Read
  const cssClasses = isDisabled ? `non-perm ${ className }` : className

  return <PermissionContext.Consumer>
    { () => currentPermission || isSuperPermission ? <div className={ cssClasses }>{ children }</div> : false }
  </PermissionContext.Consumer>
}


export const usePermission = (permission: PermissionKey) => {
  const currentPermissions = useAppSelector(permissions)
  const isSuperPermission = currentPermissions['*']
  const currentPermission = currentPermissions[permission]
  return currentPermission || isSuperPermission
}
