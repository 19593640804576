import React from 'react'
import { Provider } from 'react-redux'
import { Store } from '@reduxjs/toolkit'
import { ThemeProvider } from 'styled-components'

import { theme } from './theme'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { themeMui } from './mui-theme'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'
import { Layout } from './layout'
import './index.scss'
import 'react-toastify/dist/ReactToastify.css'

export const renderRoot = (store: Store) =>
  <Provider store={ store }>
    <ThemeProvider theme={ theme }>
      <MuiThemeProvider theme={ themeMui }>
        <ToastContainer />
        <BrowserRouter>
          <Layout.App />
        </BrowserRouter>
      </MuiThemeProvider>
    </ThemeProvider>
  </Provider>
