import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { store } from '../index'
import type { RootState } from '../'
import { API } from '../../api'
import { TApp } from '../../type'
import { loadStorage } from '../../utility/storage'

export interface AppState extends TApp {
  isAuthorized: boolean
  isLoading: boolean
}

export const initialAppState: AppState = {
  isAuthorized: false,
  isLoading: false,
  jwt: '',
  refresh: '',
  permissions: {},
  name: '',
  ce_list: []
}

export const appFetch = createAsyncThunk('appFetch', async (SAMLToken?: string) => {
  if (SAMLToken) {
    return await API.App.JWTToken(SAMLToken)
  } else {
    const { app: { refresh } } = store.getState()
    return await API.App.refreshToken(refresh)
  }
})

export const appSlice = createSlice({
  name: 'app',
  initialState: () => {
    const appStore = loadStorage('app')
    return appStore ? appStore : initialAppState
  },
  reducers: {
    logOut: (state) => {
      state.isAuthorized = false
      state.jwt = ''
      state.refresh = ''
      state.permissions = {}
      state.name = ''
      state.ce_list = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(appFetch.pending, (state) => {
        state.isAuthorized = false
        state.isLoading = true
      })
      .addCase(appFetch.rejected, (state) => {
        state.isAuthorized = false
        state.isLoading = false
      })
      .addCase(appFetch.fulfilled, (state, { payload: { jwt, refresh, permissions, name, ce_list } } ) => {
        state.isAuthorized = true
        state.isLoading = false
        state.jwt = jwt
        state.refresh = refresh
        state.permissions = permissions
        state.name = name
        state.ce_list = ce_list
      })
  }
})

export const isLoading = (state: RootState) => state.app.isLoading
export const isAuthorized = (state: RootState) => state.app.isAuthorized
export const permissions = (state: RootState) => state.app.permissions
export const name = (state: RootState) => state.app.name
export const ce_list = (state: RootState) => state.app.ce_list
export const { logOut } = appSlice.actions
export const appReducer = appSlice.reducer
