import { request } from './axios'
import { TMappingFees, TMappings, TMappingSave, TFlagsRatesRanges, TMappingFeeSave, TMappingFee } from '../type'

export const Mapping = {

  get: async (id: string, page_number = '1'): Promise<TMappings> => {
    const { data: { ['340bId']: _340bId, dea_ids: { value }, name } } = await request({
      url: `/metadata/dea_inv/?340bId=${ id }&page_number=${ page_number }`
    })

    return {
      ['340bId']: _340bId,
      dea_ids: value,
      name
    }
  },

  archive: async (_340bid: string, deaId: string, dis_inv_group: string): Promise<void> => {
    await request({
      method: 'delete',
      url: `/metadata/dea_inv/?340bId=${ _340bid }&dis_inv_group=${ dis_inv_group }&deaId=${ deaId }`
    })
  },

  fees: async (dea_id: string, inv_grp: string, page_number = 1): Promise<TMappingFees> => {
    const { data: { dea_flags_fees } } = await request({
      url: `/fees/flags_fees/?dea_id=${ dea_id }&inv_grp=${ inv_grp }&page_number=${ page_number }`,
      transformResponse: [response => {
        const data = JSON.parse(response)
        let flags_fees = data.dea_flags_fees.flags_fees
        flags_fees = flags_fees.map((item: TMappingFee) => {
          item.effective_date = new Date(`${ item.effective_date }T00:00:00.000`)
          return item
        })
        data.dea_flags_fees.flags_fees = [ ...flags_fees ]
        return data
      }]
    })

    return {
      dea_flags_fees
    }
  },

  save: async (mappings: TMappingSave[]) => {
    await request({
      method: 'post',
      url: '/metadata/dea_inv/',
      data: {
        mappings
      }
    })
  },

  flags_rates_ranges: async (page_number = 1): Promise<TFlagsRatesRanges> => {
    const { data: { flags, ranges, rates } } = await request({
      url: `/config/flags_rates_ranges/?page_number=${ page_number }`
    })
    return {
      flags,
      ranges,
      rates
    }
  },

  createFee: async (mappingFee: TMappingFeeSave) => {
    await request({
      method: 'post',
      url: '/fees/flags_fees/',
      data: mappingFee
    })
  },

  editFee: async (mappingFee: TMappingFeeSave) => {
    await request({
      method: 'put',
      url: '/fees/flags_fees/',
      data: mappingFee
    })
  },

  deleteFee: async (dea_id: string, inv_grp: string, effective_date: string) => {
    await request({
      method: 'delete',
      url: `/fees/flags_fees/?dea_id=${ dea_id }&inv_grp=${ inv_grp }&effective_date=${ effective_date }`
    })
  }
}
