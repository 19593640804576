import React from'react'
import { AddCircle } from'./AddCircle'
import { Archive } from'./Archive'
import { ArrowBack } from'./ArrowBack'
import { ArrowBottom } from'./ArrowBottom'
import { ArrowRight } from'./ArrowRight'
import { Basket } from'./Basket'
import { CheckCircle } from'./CheckCircle'
import { Database } from'./Database'
import { Dollar } from'./Dollar'
import { Eye } from'./Eye'
import { Flag } from'./Flag'
import { Github } from'./Github'
import { HandSettings } from'./HandSettings'
import { Home } from'./Home'
import { Info } from'./Info'
import { Logout } from'./Logout'
import { Pencil } from'./Pencil'
import { Pharmasee } from'./Pharmasee'
import { Range } from'./Range'
import { Search } from'./Search'
import { Support } from'./Support'
import { Upload } from'./Upload'
import { User } from'./User'

const Icons = {
  AddCircle,
  Archive,
  ArrowBack,
  ArrowBottom,
  ArrowRight,
  Basket,
  CheckCircle,
  Database,
  Dollar,
  Eye,
  Flag,
  GitHub: Github,
  HandSettings,
  Home,
  Info,
  Logout,
  Pencil,
  Pharmasee,
  Range,
  Search,
  Support,
  Upload,
  User
} as const

export type IconNames = keyof typeof Icons

export const IconsEnum = Object.keys(Icons)

interface IIcon {
  name: IconNames
  viewBox?: string
  size?: '16' | '24' | '28' | '32'
  fill?: string
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}

export const Icon: React.FC<IIcon> = ({
  name,
  viewBox= '0 0 24 24',
  size= '24',
  fill= 'currentColor',
  onClick,
  ...props
}) => (
  <svg
    width={ size }
    height={ size }
    viewBox={ viewBox }
    fill={ fill }
    onClick={ onClick }
    { ...props }
  >
    { Icons[name]() }
  </svg>
)

/*Icon.defaultProps = {
  viewBox: '0 0 24 24',
  size: '24'
}*/
