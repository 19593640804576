import variable from './scss/variables.scss'

interface CustomTheme {
  colors: {
    alert: string;
    error: string;
    success: string;
    primary200: string;
    primary500: string;
    primary700: string;
    primary900: string;
    base100: string;
    base200: string;
    base300: string;
    base400: string;
    base500: string;
    base600: string;
    base700: string;
    base800: string;
    base900: string;
    blueLink: string;
  };
  typography: {
    sizes: {
      xxs: {
        textSize: string;
        lineHeight: string;
      };
      xs: {
        textSize: string;
        lineHeight: string;
      };
      s: {
        textSize: string;
        lineHeight: string;
      };
      m: {
        textSize: string;
        lineHeight: string;
      };
      l: {
        textSize: string;
        lineHeight: string;
      };
      xl: {
        textSize: string;
        lineHeight: string;
      };
      h3: {
        textSize: string;
        lineHeight: string;
      };
      h2: {
        textSize: string;
        lineHeight: string;
      };
      h1: {
        textSize: string;
        lineHeight: string;
      };
    };
    weights: {
      regular: number;
      medium: number;
      bold: number;
      black: number;
    };
  };
}

export const theme: CustomTheme = {
  colors: {
    alert: variable.alert,
    error: variable.error,
    success: variable.success,
    primary200: variable.primary200,
    primary500: variable.primary500,
    primary700: variable.primary700,
    primary900: variable.primary900,
    base100: variable.base100,
    base200: variable.base200,
    base300: variable.base300,
    base400: variable.base400,
    base500: variable.base500,
    base600: variable.base600,
    base700: variable.base700,
    base800: variable.base800,
    base900: variable.base900,
    blueLink: variable.blueLink
  },
  typography: {
    sizes: {
      xxs: {
        textSize: variable.xxsTextSize,
        lineHeight: variable.xxsLineHeight
      },
      xs: {
        textSize: variable.xsTextSize,
        lineHeight: variable.xsLineHeight
      },
      s: {
        textSize: variable.smTextSize,
        lineHeight: variable.smLineHeight
      },
      m: {
        textSize: variable.mdTextSize,
        lineHeight: variable.mdLineHeight
      },
      l: {
        textSize: variable.lgTextSize,
        lineHeight: variable.lgLineHeight
      },
      xl: {
        textSize: variable.xlTextSize,
        lineHeight: variable.xlLineHeight
      },
      h3: {
        textSize: variable.lgTextSize,
        lineHeight: variable.lgLineHeight
      },
      h2: {
        textSize: variable.xlTextSize,
        lineHeight: variable.xlLineHeight
      },
      h1: {
        textSize: variable.xxlTextSize,
        lineHeight: variable.xxlLineHeight
      }
    },
    weights: {
      regular: variable.regular,
      medium: variable.medium,
      bold: 600,
      black: variable.black
    }
  }
}
