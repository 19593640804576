import { request } from './axios'
import { TDeas, TDea as TDea } from '../type'

export const Dea = {

  getByDeas: async (list: string[]): Promise<TDea[]> => {
    const { data: { deas: { value } } } = await request({
      url: `/metadata/dea/list/?id_list=${ list.join() }`
    })
    return value
  },

  get: async (search_key = '', page_number = 1, isShort = false): Promise<TDeas> => {
    const short = isShort ? '&short=true': ''
    const { data: { deas: { value }, page_no, total_count, total_pages } } = await request({
      url: `/metadata/dea/?page_number=${ page_number }&search_key=${ search_key }${ short }`
    })
    return {
      deas: value,
      page_no,
      total_count,
      total_pages
    }
  }
}
