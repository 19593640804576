import { request } from './axios'
import { TRate } from '../type'

export const Rate = {

  get: async (page_number = 1): Promise<TRate[]> => {
    const { data: { rates } } = await request({
      url: `/config/rates/?page_number=${ page_number }`
    })
    return rates
  },

  add: (rates: TRate[]) => request({
    method: 'post',
    url: '/config/rates/',
    data: {
      rates
    }
  }),

  update: (rates: TRate[]) => request({
    method: 'put',
    url: '/config/rates/',
    data: {
      rates
    }
  }),

  delete: async (name: string): Promise<number> => {
    const { status } = await request({
      method: 'delete',
      url: `/config/rates/?rate_name=${ name }`
    })
    return status
  },

  fetch: async (): Promise<TRate[]> => {
    const { data: { new_items } } = await request({
      url: '/dev/configurations/fetch/missing/?type=Rates'
    })
    return new_items
  }
}
