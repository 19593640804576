const locale = 'en-US'

export const toUTC = (date: string | Date): Date => new Date(new Date(date).toLocaleDateString(
  locale,
  { timeZone: 'UTC' }
))

export enum Format {
  'DD MMM YYYY' = 'DD MMM YYYY',
  'YYYY/MM/DD' = 'YYYY/MM/DD',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'HH MM' = 'HH MM',
  'HH:MM:SS' = 'HH:MM:SS',
  'YYYY-MM-DD HH:MM:SS' = 'YYYY-MM-DD HH:MM:SS',
  'YYYY-MM-DD__HH:MM:SS' = 'YYYY-MM-DD__HH:MM:SS'
}

export const dateFormat = (date: string | Date, format: Format) => {
  const value = new Date(date)

  const getDate = (options: Record<string, unknown>) => value.toLocaleDateString(locale, options)

  let result: string
  switch (format) {
    case Format['DD MMM YYYY']:
      result = `${ getDate({ day: 'numeric' }) } ${ getDate({ month: 'short' }) } ${ value.getFullYear() }`
      break
    case Format['YYYY/MM/DD']:
      result = `${ value.getFullYear() }/${ getDate({ month: '2-digit' }) }/${ getDate({ day: '2-digit' }) }`
      break
    case Format['YYYY-MM-DD']:
      result = `${ value.getFullYear() }-${ getDate({ month: '2-digit' }) }-${ getDate({ day: '2-digit' }) }`
      break
    case Format['HH MM']:
      result = new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' })
      break
    case Format['YYYY-MM-DD HH:MM:SS']:
      result = `${ value.getFullYear() }-${ getDate({ month: '2-digit' }) }-${ getDate({ day: '2-digit' }) } ${ new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', second: '2-digit' }) }`
      break
    case Format['HH:MM:SS']:
      result =  new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', second: '2-digit' })
      break
    case Format['YYYY-MM-DD__HH:MM:SS']:
      result = `${ value.getFullYear() }-${ getDate({ month: '2-digit' }) }-${ getDate({ day: '2-digit' }) }__${ new Date(date).toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit', second: '2-digit' }) }`
      break
    default:
      result = String(value)
  }
  return result
}
