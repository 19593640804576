import { request } from './axios'
import { TValidationRules, TUploadedTemplate } from '../type'

export const ValidationRule = {

  get: async (file_type: string): Promise<TValidationRules> => {
    const { data: { fields_validation, upload_date } } = await request({
      url: `/aux/fields_validation/?file_type=${ file_type }`
    })

    return {
      fields_validation,
      upload_date
    }
  },

  upload: async (file_type: string, file: File, onUpdateProgress: (progress: number) => void): Promise<TUploadedTemplate> => {

    const formData = new FormData()
    formData.append(file.name, file)

    const { data } = await request({
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: `/aux/upload/template/?file_type=${ file_type }`,
      method: 'post',
      data: formData,
      onUploadProgress: (progressEvent) => {
        if (progressEvent?.total) {
          onUpdateProgress(Math.round((progressEvent.loaded * 100) / progressEvent?.total))
        }
      }
    })

    return data
  }
}

