import React, { useState, useEffect, KeyboardEvent } from 'react'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { TDea, ISelect } from '../../type'
import { API } from '../../api'

interface Props {
  pharmacy?: TDea;
  isDisabled?: boolean;
  isDefault?: boolean;
  isShort?: boolean;
  onChange: (value: TDea) => void;
  testName?: string;
  dataTest?: string;
  sx?: any;
}

const adapterSelectValue = ({ name, deaId }: TDea): ISelect => ({
  value: deaId,
  label: deaId ? `${name} (${deaId})` : '',
})

export const Pharmacies: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [deas, setDeas] = useState<TDea[]>([])
  const [pharmacies, setPharmacies] = useState<ISelect[]>([])
  const [search, setSearch] = useState('')
  const [pageNo, setPageNo] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [selectedPharmacy, setSelectedPharmacy] = useState<ISelect | null>(
    props.pharmacy ? adapterSelectValue(props.pharmacy) : null
  )

  useEffect(() => {
    if (props.pharmacy) {
      setSelectedPharmacy(adapterSelectValue(props.pharmacy))
    }
  }, [props.pharmacy])

  useEffect(() => {
    if (!props.isDisabled) {
      getPharmacies()
    }
  }, [props.isDisabled])

  useEffect(() => {
    if (!search && pharmacies.length > 0 && !selectedPharmacy) {
      handlePharmacy(pharmacies[0])
    }
  }, [pharmacies])

  useEffect(() => {
    if (!search && props.isDefault && pharmacies.length > 0) {
      const firstPharmacy = pharmacies[0]
      setSelectedPharmacy(firstPharmacy)
      props.onChange(deas.find(dea => dea.deaId === firstPharmacy.value) as TDea)
    }
  }, [pharmacies])

  const getPharmacies = async () => {
    setIsLoading(true)
    const { deas: APIDeas, total_pages } = await API.Dea.get(search, pageNo, props.isShort)

    const combinedDeas = [...deas, ...APIDeas]
    const uniqueDeas = Array.from(new Set(combinedDeas.map(dea => dea.deaId)))
      .map(id => combinedDeas.find(dea => dea.deaId === id))
      .filter((dea): dea is TDea => dea !== undefined)

    setDeas(uniqueDeas)
    setPharmacies(uniqueDeas.map(dea => adapterSelectValue(dea)))
    setTotalPages(total_pages ?? 1)
    setIsLoading(false)
  }

  const handlePharmacy = (pharmacy: ISelect | null) => {
    setSelectedPharmacy(pharmacy)
    handleChange(pharmacy ? pharmacy.value : '')
  }

  const handleChange = (value: string) => {
    const dea = deas.find(({ deaId }) => deaId === value) || {
      deaId: '',
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
    }
    props.onChange(dea as TDea)
  }

  const handleInputChange = (event: React.SyntheticEvent, value: string, reason: any) => {
    if (reason === 'input') {
      setSearch(value)
    }
  }

  const handleMenuScrollToBottom = () => {
    if (totalPages > pageNo) {
      setPageNo(prevPageNo => prevPageNo + 1)
      getPharmacies()
    }
  }

  const handleKeyDown = async (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setDeas([])
      setPharmacies([])
      setPageNo(1)
      await getPharmacies()
      event.preventDefault()
    }
  }

  return (
    <Autocomplete
      sx={props.sx}
      id={props.testName}
      value={selectedPharmacy || {
        label: '',
        value: ''
      }}
      disableClearable
      options={pharmacies}
      getOptionLabel={option => option.label}
      onChange={(_, newValue) => handlePharmacy(newValue as ISelect | null)}
      size='small'
      onInputChange={handleInputChange}
      ListboxProps={{
        onScroll: event => {
          const listboxNode = event.currentTarget
          const bottom = Math.ceil(listboxNode.scrollTop + listboxNode.clientHeight) >= listboxNode.scrollHeight
          if (bottom) {
            handleMenuScrollToBottom()
          }
        },
      }}
      onKeyDown={handleKeyDown}
      renderInput={params => (
        <TextField
          {...params}
          inputProps={{
            ...params.inputProps,
            'data-test': props.dataTest,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress
                  color='inherit'
                  size={20}
                /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
            style: {
              minHeight: '48px',
              display: 'flex',
              alignItems: 'center',
              paddingRight: '30px',
            },
          }}
        />
      )}
      disabled={props.isDisabled}
    />
  )
}
