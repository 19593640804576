import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../'
import { API } from '../../api'
import { TADConstant } from '../../type'

export interface StateADManagement {
  constant: TADConstant
  visitedUserId: string
  visitedCEId: string
  visitedMembershipId: string
}

export const initialStateADManagement: StateADManagement = {
  constant: {} as TADConstant,
  visitedUserId: '',
  visitedCEId: '',
  visitedMembershipId: ''
}

export const getADConstants = createAsyncThunk(
  'ADConstant/fetch',
  async () => await API.ADManagement.constants()
)

export const sliceADManagement = createSlice({
  name: 'ADManagement',
  initialState: initialStateADManagement,
  reducers: {
    setVisitedUserId: (state, action) => {
      state.visitedUserId = action.payload
    },
    setVisitedCEId: (state, action) => {
      state.visitedCEId = action.payload
    },
    setVisitedMembershipId: (state, action) => {
      state.visitedMembershipId = action.payload
    },
    resetVisitedId: state => {
      state.visitedUserId = ''
      state.visitedCEId = ''
      state.visitedMembershipId = ''
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getADConstants.fulfilled, (state, { payload }) => {
        state.constant = payload
      })
  }
})

export const ADManagementConstants = (state: RootState) => state.ADManagement.constant
export const visitedUserId = (state: RootState) => state.ADManagement.visitedUserId
export const visitedCEId = (state: RootState) => state.ADManagement.visitedCEId
export const visitedMembershipId = (state: RootState) => state.ADManagement.visitedMembershipId
export const reducerADManagement = sliceADManagement.reducer
export const { setVisitedUserId, setVisitedCEId, setVisitedMembershipId, resetVisitedId } = sliceADManagement.actions
