export const loadStorage = (item: string) => {
  try {
    const serializedState = sessionStorage.getItem(item)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (error) {
    console.error(`loadStorage error ${error}`)
    return undefined
  }
}

export const saveStorage = (key: string, state: any) => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem(key, serializedState)
  } catch (error) {
    console.error(`saveStorage error ${error}`)
  }
}

export const removeStorage = (item: string) => {
  sessionStorage.removeItem(item)
}
