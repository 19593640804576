import { request } from './axios'
import { TInvoicesMappings } from '../type'

export const InvoicesMappings = {

  get: async (distributor: string): Promise<TInvoicesMappings> => {
    const { data } = await request({
      url: `aux/mapping/?distributor=${ distributor }`
    })

    return data
  },

  update: async (distributor: string, invoices_mapping: object): Promise<void> => {
    await request({
      url: `aux/mapping/?distributor=${ distributor }`,
      method: 'post',
      data: {
        invoices_mapping
      }
    })
  }
}
