import { request } from './axios'
import { TApp as IApp } from '../type'
import { decodeJWT } from '../utility/jwt'

export const App = {

  JWTToken: async (SAMLToken: string): Promise<IApp> => {
    const { data: { jwt, refresh } } = await request({
      url: '/auth/token/',
      method: 'post',
      data: {
        t: SAMLToken
      }
    })

    const { permissions, name, ce_list } = decodeJWT(jwt)

    return {
      jwt,
      refresh,
      permissions,
      name,
      ce_list
    }
  },

  refreshToken: async (refresh_token: string): Promise<IApp> => {
    const { data: { jwt, refresh } } = await request({
      url: '/auth/refresh/',
      method: 'post',
      data: {
        refresh_token
      }
    })

    const { permissions, name, ce_list } = decodeJWT(jwt)

    return {
      jwt,
      refresh,
      permissions,
      name,
      ce_list
    }
  }
}
