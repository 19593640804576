import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../'
import { API } from '../../api'
import { ISelect } from '../../type'

export interface DistributorState {
  AUX_DISTRIBUTORS: ISelect[],
  AUX_DISTRIBUTORS_MAPPINGS: ISelect[],
  AUX_FILE_TYPES: ISelect[],
}

export const initialDistributorState: DistributorState = {
  AUX_DISTRIBUTORS: [],
  AUX_DISTRIBUTORS_MAPPINGS: [],
  AUX_FILE_TYPES: []
}

export const getDistributors = createAsyncThunk(
  'getDistributors/fetch',
  async () => {
    const { AUX_DISTRIBUTORS, AUX_DISTRIBUTORS_MAPPINGS, AUX_FILE_TYPES } = await API.Distributor.get()
    return { AUX_DISTRIBUTORS, AUX_DISTRIBUTORS_MAPPINGS, AUX_FILE_TYPES }
  }
)

export const distributorSlice = createSlice({
  name: 'distributor',
  initialState: initialDistributorState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDistributors.fulfilled, (state, { payload: { AUX_DISTRIBUTORS, AUX_DISTRIBUTORS_MAPPINGS, AUX_FILE_TYPES } }) => {
        state.AUX_DISTRIBUTORS = AUX_DISTRIBUTORS
        state.AUX_DISTRIBUTORS_MAPPINGS = AUX_DISTRIBUTORS_MAPPINGS
        state.AUX_FILE_TYPES = AUX_FILE_TYPES
      })
  }
})

export const AUX_DISTRIBUTORS = (state: RootState) => state.distributor.AUX_DISTRIBUTORS
export const AUX_DISTRIBUTORS_MAPPINGS = (state: RootState) => state.distributor.AUX_DISTRIBUTORS_MAPPINGS
export const AUX_FILE_TYPES = (state: RootState) => state.distributor.AUX_FILE_TYPES
export const distributorReducer = distributorSlice.reducer
