import { request } from './axios'
import type { TRole, TRoles } from '../type'

export const Role = {

  get: async (user_role = ''): Promise<TRoles> => {
    const { data: { role_permission_map, upload_date } } = await request({
      url: `/auth/permissions/?user_role=${ user_role }`
    })

    return {
      role_permission_map,
      upload_date
    }
  },

  update: async (data: TRoles): Promise<void> => {
    await request({
      url: '/auth/permissions/',
      method: 'post',
      data
    })
  },

  edit: async (role: string, data: TRole): Promise<void> => {
    await request({
      url: `/auth/permissions/?user_role=${ role }`,
      method: 'put',
      data
    })
  },

  add: async (data: TRole) => {
    await request({
      url: '/auth/permissions/role/',
      method: 'post',
      data
    })
  },

  delete: async (user_role = ''): Promise<void> => {
    await request({
      url: `/auth/permissions/?user_role=${ user_role }`,
      method: 'delete'
    })
  }
}
