import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PATH } from '../../utility/path'
import { View } from '../../view'
import { Preloader } from '../../component/common'

export const Routers = () => <Suspense fallback={
  <Preloader
    size='lg'
    isShow={ true }
    className='m-l-auto m-r-auto m-t-auto m-b-auto'
  /> }
>
  <Routes>
    <Route
      path={ PATH.home }
      element={ <View.Home /> }
    />
    <Route element={ <View.Upload.Wrap /> }>
      <Route
        index
        path={ PATH.upload.upload }
        element={ <View.Upload.Upload /> }
      />
      <Route
        path={ PATH.upload.history }
        element={ <View.Upload.History /> }
      />
    </Route>
    <Route
      path={ `${PATH.admin.category_classification}/:logic/category/add` }
      element={ <View.Admin.CategoryClassification /> }
    />
    <Route
      path={ `${PATH.admin.category_classification}/:logic/category/:category` }
      element={ <View.Admin.CategoryClassification /> }
    />
    <Route
      index
      path={ PATH.admin.category_classifications }
      element={ <View.Admin.CategoryClassifications /> }
    />
    <Route
      path={ `${PATH.admin.category_classification}/:create` }
      element={ <View.Admin.CategoryClassification /> }
    />
    <Route element={ <View.FeeCalculation.FieldConfiguration /> }>
      <Route
        path={ PATH.fee_calculation.field_configuration.flags }
        element={ <View.FeeCalculation.Flags /> }
      />
      <Route
        path={ PATH.fee_calculation.field_configuration.rates }
        element={ <View.FeeCalculation.Rates /> }
      />
      <Route
        path={ PATH.fee_calculation.field_configuration.ranges }
        element={ <View.FeeCalculation.Ranges /> }
      />
    </Route>
    <Route element={ <View.FeeCalculation.FeeManagement /> }>
      <Route
        path={ PATH.fee_calculation.fee_management.fees }
        element={ <View.FeeCalculation.CoveredEntities /> }
      />
      <Route
        path={ PATH.fee_calculation.fee_management.drafts }
        element={ <View.FeeCalculation.Drafts /> }
      />
    </Route>
    <Route
      path={ `${ PATH.fee_calculation.fee_management.mappings }/:id` }
      element={ <View.FeeCalculation.Mappings /> }
    />
    <Route
      path={ PATH.support }
      element={ <View.Support /> }
    />
    <Route
      path={ PATH.admin.synchronization }
      element={ <View.Admin.Synchronization /> }
    />
    <Route element={ <View.Admin.UserRolesManagement /> }>
      <Route
        index
        path={ PATH.admin.user_roles_management.permissions }
        element={ <View.Admin.Permissions /> }
      />
      <Route
        path={ PATH.admin.user_roles_management.resources }
        element={ <View.Admin.Resources /> }
      />
    </Route>
    <Route element={ <View.Admin.AuxRulesManagement /> }>
      <Route
        index
        path={ PATH.admin.aux_rules_management.validation_rules }
        element={ <View.Admin.ValidationRules /> }
      />
      <Route
        path={ PATH.admin.aux_rules_management.upload_template }
        element={ <View.Admin.UploadTemplate /> }
      />
    </Route>
    <Route
      path={ PATH.admin.ad_management.users }
      element={ <View.Admin.ADManagement.Users /> }
    />
    <Route
      path={ `${ PATH.admin.ad_management.user }/:name/external` }
      element={ <View.Admin.ADManagement.ExternalUser /> }
    />
    <Route
      path={ `${ PATH.admin.ad_management.user }/:name/internal` }
      element={ <View.Admin.ADManagement.InternalUser /> }
    />
    <Route
      path={ PATH.admin.ad_management.covered_entities }
      element={ <View.Admin.ADManagement.CoveredEntities /> }
    />
    <Route
      path={ `${ PATH.admin.ad_management.covered_entity }/:name` }
      element={ <View.Admin.ADManagement.CoveredEntity /> }
    />
    <Route
      path={ PATH.admin.ad_management.memberships }
      element={ <View.Admin.ADManagement.Memberships /> }
    />
    <Route
      path={ `${ PATH.admin.ad_management.membership }/:name` }
      element={ <View.Admin.ADManagement.Membership /> }
    />
    <Route
      path={ PATH.pharmasee }
      element={ <View.Pharmasee /> }
    />
    { process.env.NODE_ENV === 'development' ? <Route
      path={ PATH.sandbox }
      element={ <View.Sandbox /> }
    /> : false }
  </Routes>
</Suspense>
