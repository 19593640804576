import { request } from './axios'
import { TDistributor } from '../type'

export const Distributor = {

  get: async (): Promise<TDistributor> => {
    const { data: { AUX_DISTRIBUTORS, AUX_DISTRIBUTORS_MAPPINGS, AUX_FILE_TYPES } } = await request({
      url: '/aux/meta/'
    })

    return {
      AUX_DISTRIBUTORS,
      AUX_DISTRIBUTORS_MAPPINGS,
      AUX_FILE_TYPES
    }
  }
}
