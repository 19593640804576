import { request, resetCustomOnResponseError, setCustomOnResponseError } from './axios'
import { TCategoryClassification, TLogic } from '../type'
import { TPutCategoryRow, TPutCategoryClassification, TRowArchive, TCategoryRow, TCategory } from '../type/category-classification'
import { AxiosError } from 'axios'
import { appFetch } from '../store/reducer/app'
import { store } from '../store'
import { toast } from 'react-toastify'

const customErrorResponse = async (error: AxiosError): Promise<any> => {
  const { response }: AxiosError = { ...error }

  if (response && response.data) {
    const errorMessage = (response.data as { 'Error Message'?: { details: string, error_code: string } })['Error Message']
    if (errorMessage) {
      const { details, error_code } = errorMessage
      const { status } = response

      if (status === 401 || status === 403) {
        if (error_code === 'ER-401-2') {
          await store.dispatch(appFetch())
        }
      } else if (status === 422 && error_code === 'ER-422-3') {
        let errorMessages = []

        if (typeof details === 'string') {
          errorMessages.push(details)
        } else {
          errorMessages = Object.values(details['json'] || {}).flat()
        }

        if (errorMessages.length > 0) {
          toast(errorMessages[0] as string, {
            type: 'error',
            position: 'top-center'
          })
        }
      } else {
        toast(JSON.stringify(details), {
          type: 'error',
          position: 'top-center'
        })
      }
    }
  }
  console.error({ ...error })
  return Promise.reject({ ...error })
}

export const CategoryClassification = {

  listLogic: async (): Promise<TLogic[]> => {
    const { data } = await request({
      url: '/category-classification/logic/'
    })
    return data
  },

  addLogic: (categoryClassification: TCategoryClassification) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/category-classification/logic/',
      data: categoryClassification
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  updateLogic: (oldName: string, newName: string) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'put',
      url: '/category-classification/logic/',
      data: {
        old_name: oldName,
        new_name: newName
      }
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  archiveLogic: (name: string): Promise<number> => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'delete',
      url: '/category-classification/logic/?name=' + name,
    }).finally(() => {
      resetCustomOnResponseError()
    }).then(response => response.status)
  },

  addCategory: (categoryClassification: TCategoryClassification) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/category-classification/category/',
      data: categoryClassification
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  updateCategory: (categoryClassification: TPutCategoryClassification) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'put',
      url: '/category-classification/category/',
      data: categoryClassification
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  archiveCategory: (name: string) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'delete',
      url: '/category-classification/category/?name=' + name,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  listCategoryRows: async (categoryName: string): Promise<TCategory> => {
    const { data } = await request({
      url: `/category-classification/rows/${categoryName}/`
    })
    return data
  },

  addCategoryRow: (payload: TCategoryRow) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/category-classification/rows/',
      data: payload
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  updateCategoryRow: (payload: TPutCategoryRow) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'put',
      url: '/category-classification/rows/',
      data: payload
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  archiveCategoryRow: (row: TRowArchive) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'delete',
      url: '/category-classification/rows/?logic=' + row.logic + '&category=' + row.category + '&type=' + row.type + '&id=' + row.id + '&effective_start_date=' + row.effective_start_date,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },

  checkLogicAndTypes: async (logic: string, types: string[]): Promise<{name: string, exists: boolean}[]> => {
    const { data } = await request({
      url: '/category-classification/rows/check/',
      params: {
        logic,
        types: types.join(',')
      }
    })

    return data
  },
}
