import { configureStore, combineReducers, PreloadedState } from '@reduxjs/toolkit'
import { appReducer } from './reducer/app'
import { distributorReducer } from './reducer/distributor'
import { reducerADManagement } from './reducer/ad-management'
import { saveStorage } from '../utility/storage'

const reducers = {
  app: appReducer,
  distributor: distributorReducer,
  ADManagement: reducerADManagement
}

const rootReducer = combineReducers(reducers)

export const setupStore = (preloadedState?: PreloadedState<RootState>) => configureStore({
  reducer: rootReducer,
  preloadedState
})

export const store = configureStore({
  reducer: reducers
})

store.subscribe(() => {
  const { jwt, refresh, isAuthorized, permissions, name, ce_list } = store.getState().app
  saveStorage('app', { jwt, refresh, isAuthorized, permissions, name, ce_list })
})

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
// export type AppDispatch = typeof store.dispatch
export type AppDispatch = AppStore['dispatch']
