import React from 'react'
import { Icon } from '../icon'
import './header.scss'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { isAuthorized, logOut, name } from '../../store/reducer/app'
import { NavLink, useNavigate } from 'react-router-dom'
import { PATH } from '../../utility/path'
import logo from '../../asset/logo.svg'
import { removeStorage } from '../../utility/storage'

export const Header = () => {
  const navigate = useNavigate()
  const isAuth = useAppSelector(isAuthorized)
  const userName = useAppSelector(name)
  const dispatch = useAppDispatch()

  const exit = () => {
    dispatch(logOut())
    removeStorage('app')
    navigate('/')
  }

  return (
    <header className='f f-d-r f-ai-c p-x-md bg-base100'>
      <NavLink to={ PATH.home }>
        <img
          src={ logo }
          alt='Pharmalytiq'
        />
      </NavLink>
      { isAuth ? <>
        <span className='t-w-600 m-l-auto m-r-md t-c-primary500'>
          { userName }
        </span>
        <Icon
          className='t-c-base700 o-c-p t-c-primary500--h'
          name='Logout'
          size='32'
          onClick={ () => exit() }
        />
      </> : false }
    </header>
  )
}
