const createDownloadLink = (data: any, file_name: string) => {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', file_name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export {
  createDownloadLink
}
